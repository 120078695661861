import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/fonts/@fontsource-variable/red-hat-display';
import './assets/fonts/@fontsource-variable/red-hat-text';
import '../node_modules/aos/dist/aos.css'
import './styles/scss/style.scss';
import Pardot from './components/Pardot';
import HubSpot from './components/HubSpot';
import BrandUni from './components/BrandUni';

const domPardot = document.getElementById('mb-pardot');
const domHubSpot = document.getElementById('mb-hubspot');
const domBrandUni = document.getElementById('branduni');

if (domPardot) {
  const root = ReactDOM.createRoot(domPardot);

  const source = document.getElementById('mb-pardot');
  const data = JSON.parse(source.getAttribute('data-settings'));

  root.render(
    <React.StrictMode>
      <Pardot {...data} />
    </React.StrictMode>
  );
}

if (domHubSpot) {
  const root = ReactDOM.createRoot(domHubSpot);

  const source = document.getElementById('mb-hubspot');
  const data = JSON.parse(source.getAttribute('data-settings'));

  root.render(
    <React.StrictMode>
      <HubSpot {...data} />
    </React.StrictMode>
  );
}

if (domBrandUni) {
  const root = ReactDOM.createRoot(domBrandUni);

  const source = document.getElementById('branduni');
  const data = JSON.parse(source.getAttribute('data-settings'));

  root.render(
    <React.StrictMode>
      <BrandUni {...data} />
    </React.StrictMode>
  );
}